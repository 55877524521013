import { createStore, createLogger } from 'vuex'
import { addons, links, packages, strings } from './definitions.js';
import { conquest_net_new_strings, conquest_switch_strings, current_client_upgrade_strings } from './definitions.js';
import { isLocal, isLocalOrDev } from '../common.js';
import Axios from 'axios'

export default createStore({
  plugins: [isLocalOrDev() ? createLogger() : () => null],
  state: {
    packages: packages,
    links: links,
    addons: addons,
    strings: strings,
    dynamicStrings: {},
    sub: "",
    error: null,
    opportunity: null,
    opportunityOriginal: null,
    originalPackages: [],
    loaded: { opportunity: false },
    modal: { show: false, title: "", subview: "" },
    modalIncompleteItemsSubview: { messages: [] },
    modalErrorMessageSubview: { message: "" },
    modalSignatureSubview: {  },
    showLoadingSplash: false,
    selectedPackages: [],
    packagesToDisplay: [],
    sourceCampaign: "NET NEW",
    isOnHighestRMPackage: false,
    isOnHighestSMRMPackage: false,
    isOnHighestGooglePackage: false,
    selectedAddon: "",
    previousPackage: "",
    googleOptOut: false,
    addonOptOut: false,
    signature: { completed: false, type: "", data: "" }
  },
  mutations: {
    INIT_OPPORTUNITY(state, payload) {
      state.opportunity = payload;
      state.opportunityOriginal = JSON.parse(JSON.stringify(payload));
    },
    INIT_PRE_SELECTED_PACKAGES(state) {
      state.sourceCampaign = getStandardSourceCampaignName(state.opportunityOriginal);
      // Package pre-selections
      // state.selectedPackages.push(packages.reputationEngage, packages.socialDominate, packages.googleBusinessProfileDominate);

      // Disable addons
      state.selectedAddon = "";

      if (getStandardSourceCampaignName(state.opportunityOriginal) == "CURRENT CLIENT UPGRADE") {
        var standardPackageNames = getStandardPackageNameArray(state.opportunityOriginal.packages);

        if (standardPackageNames.includes("REPUTATION DEFEND")) {
          state.packagesToDisplay.push(packages.reputationDefend);
          state.packagesToDisplay.push(packages.reputationEngage);

          state.selectedPackages.push(packages.reputationEngage);
        }
        else if (standardPackageNames.includes("REPUTATION ENGAGE")) {
          state.packagesToDisplay.push(packages.reputationEngage);

          state.selectedPackages.push(packages.reputationEngage);
        }
        
        if (standardPackageNames.includes("SOCIAL DOMINATE")) {
          state.packagesToDisplay.push(packages.socialDominate);

          state.selectedPackages.push(packages.socialDominate);
        }
        
        if (standardPackageNames.includes("GOOGLE BUSINESS PROFILE DEFEND")) {
          state.packagesToDisplay.push(packages.googleBusinessProfileDefend);
          state.packagesToDisplay.push(packages.googleBusinessProfileDominate);

          state.selectedPackages.push(packages.googleBusinessProfileDominate);
        }
        else if (standardPackageNames.includes("GOOGLE BUSINESS PROFILE DOMINATE")) {
          state.packagesToDisplay.push(packages.googleBusinessProfileDominate);

          state.selectedPackages.push(packages.googleBusinessProfileDominate);
        }

        if (!standardPackageNames.includes("REPUTATION DEFEND") && !standardPackageNames.includes("REPUTATION ENGAGE")) {
          state.isOnHighestRMPackage = true;

          if (!standardPackageNames.includes("SOCIAL DOMINATE")) {
            state.isOnHighestSMRMPackage = true;
          }
        }

        if (!standardPackageNames.includes("GOOGLE BUSINESS PROFILE DEFEND") && !standardPackageNames.includes("GOOGLE BUSINESS PROFILE DOMINATE")) {
          state.isOnHighestGooglePackage = true;
        }
      }
      else {
        state.packagesToDisplay = [
          packages.reputationDefend,
          packages.reputationEngage,
          packages.socialDominate,
          packages.googleBusinessProfileDefend,
          packages.googleBusinessProfileDominate
        ];

        state.selectedPackages.push(packages.reputationEngage, packages.socialDominate, packages.googleBusinessProfileDominate);
      }

        // DETERMINE WHICH PACKAGES TO DISPLAY BASED ON PRIOR PACKAGES
        // var standardPackageNames = getStandardPackageNameArray(state.opportunityOriginal.packages);
        // state.originalPackages = standardPackageNames;

        // if (standardPackageNames.includes("REPUTATION DEFEND")) {
        //   state.packagesToDisplay = state.packagesToDisplay.filter(pkg => !pkg.name.toUpperCase().includes("REPUTATION DEFEND"));
          
        // }
        
        // if (standardPackageNames.includes("REPUTATION ENGAGE")) {
        //   state.packagesToDisplay = state.packagesToDisplay.filter(pkg => !pkg.name.toUpperCase().includes("REPUTATION DEFEND"));
        //   state.packagesToDisplay = state.packagesToDisplay.filter(pkg => !pkg.name.toUpperCase().includes("REPUTATION ENGAGE"));

        //   state.isOnHighestRMPackage = true;
        // }

        // if (standardPackageNames.includes("SOCIAL DOMINATE")) {
        //   state.packagesToDisplay = state.packagesToDisplay.filter(pkg => !pkg.name.toUpperCase().includes("SOCIAL DOMINATE"));

        //   if(state.isOnHighestRMPackage) {
        //     state.isOnHighestSMRMPackage = true;
        //   }
        // }

        // if (standardPackageNames.includes("GOOGLE BUSINESS PROFILE DEFEND")) {
        //   state.packagesToDisplay = state.packagesToDisplay.filter(pkg => !pkg.name.toUpperCase().includes("GOOGLE BUSINESS PROFILE DEFEND"));
        // }

        // if (standardPackageNames.includes("GOOGLE BUSINESS PROFILE DOMINATE")) {
        //   state.packagesToDisplay = state.packagesToDisplay.filter(pkg => !pkg.name.toUpperCase().includes("GOOGLE BUSINESS PROFILE DEFEND"));
        //   state.packagesToDisplay = state.packagesToDisplay.filter(pkg => !pkg.name.toUpperCase().includes("GOOGLE BUSINESS PROFILE DOMINATE"));

        //   state.isOnHighestGooglePackage = true;
        // }
      
    },
    INIT_DYNAMIC_STRINGS(state, payload) {
      state.dynamicStrings = payload;
    },
    INIT_PACKAGES(state, payload) {
      state.packages = payload;
    },
    INIT_ADDONS(state, payload) {
      state.addons = payload;
    },
    INIT_LINKS(state, payload) {
      state.links = payload;
    },
    UPDATE_DEALER_INFO(state, payload) {
      state.opportunity.dealerInformation[payload.prop] = payload.value;
    },
    UPDATE_STATE(state, { prop, value }) {
      state[prop] = value;
    },
    UPDATE_OPPORTUNITY(state, payload) {
      state.opportunity[payload.prop] = payload.value;
    },
    UPDATE_ADDRESS(state, payload) {
      state.opportunity.address[payload.prop] = payload.value;
    },
    UPDATE_SELECTED_ADDON(state, payload) {
      state.selectedAddon = payload;
    },
    UPDATE_ERROR(state, payload) {
      state.error = payload;
    },
    UPDATE_LOADING_STATUS(state, { prop, value }) {
      state.loaded[prop] = value;
    },
    UPDATE_SIGNATURE(state, payload) {
      state.signature = payload;
    },
    SHOW_INCOMPLETE_ITEMS_MODAL(state, { messages, title }) {
      state.modalIncompleteItemsSubview.messages = messages;
      state.modal.title = title;
      state.modal.subview = "incompleteItems";
      state.modal.show = true;
    },
    SHOW_ERROR_MODAL(state, { message, title }) {
      state.modalErrorMessageSubview.message = message;
      state.modal.title = title;
      state.modal.subview = "error";
      state.modal.show = true;
    },
    SHOW_SIGNATURE_MODAL(state) {
      state.modal.title = "Sign";
      state.modal.subview = "signature";
      state.modal.show = true;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    SHOW_MODAL(state) {
      state.modal.show = true;
    },
    SHOW_LOADING_SPLASH(state) {
      state.showLoadingSplash = true;
    },
    ADD_PACKAGE(state, pkg) {
      state.selectedPackages.push(pkg);
    },
    TOGGLE_GM_PACKAGE(state, pkg) {
      const index = state.selectedPackages.findIndex(statePackage => statePackage.id === pkg.id);

      if (pkg.subtype === 'reputation') {
        // Remove other reputation packages if this one is being added
        if (index === -1) {
          state.selectedPackages = state.selectedPackages.filter(statePackage => statePackage.subtype !== 'reputation');
          state.selectedPackages.push(pkg);
        } else {
          // Remove this reputation package
          state.selectedPackages.splice(index, 1);
        }
      } else if (pkg.subtype === 'social') {
        if (index !== -1) {
          // Remove the social package if it's already selected
          state.selectedPackages.splice(index, 1);
        } else {
          // Add the social package if it's not selected
          state.selectedPackages.push(pkg);
        }
      }

      // No further processing required for upgrade.
      if(state.sourceCampaign == "CURRENT CLIENT UPGRADE") {
        return;
      }
    
      // Handle automatic re-selection of GM Reputation Defend
      const isReputationSelected = state.selectedPackages.some(statePackage => statePackage.subtype === 'reputation');
      const isSocialSelected = state.selectedPackages.some(statePackage => statePackage.subtype === 'social');
    
      if (!isReputationSelected && !isSocialSelected) {
        const defaultPackage = packages.reputationEngage;
        if (!state.selectedPackages.some(statePackage => statePackage.id === defaultPackage.id)) {
          state.selectedPackages.push(defaultPackage);
        }
      }
    },
    TOGGLE_GOOGLE_PACKAGE(state, pkg) {
      if (pkg.type !== 'Google')
        return;
    
      const index = state.selectedPackages.findIndex(statePackage => statePackage.id === pkg.id);
    
      if (index !== -1) {
        state.selectedPackages.splice(index, 1);

        if(pkg.simpleName == "GOOGLE BUSINESS PROFILE DOMINATE" && state.originalPackages.includes("GOOGLE BUSINESS PROFILE DEFEND")) {
          state.selectedPackages.push(packages.googleBusinessProfileDefend);
        }
        
      } else {
        state.selectedPackages = state.selectedPackages.filter(statePackage => statePackage.type !== 'Google');
        state.selectedPackages.push(pkg);
        state.googleOptOut = false;
      }
    },    
    GOOGLE_OPT_OUT(state) {
      if(state.googleOptOut == true) {
        state.googleOptOut = false;
        if(state.originalPackages.includes("GOOGLE BUSINESS PROFILE DEFEND")) {
          state.selectedPackages.push(packages.googleBusinessProfileDefend);
        }
      }
      else {
        state.googleOptOut = true;
        state.selectedPackages = state.selectedPackages.filter(pkg => pkg.type != 'Google');
      }
    },
    ADDON_OPT_OUT(state) {
      state.addonOptOut = true;

      state.selectedAddon = null;
    },
    HIDE_MODAL(state) {
      state.modal.show = false;
    },
    HIDE_LOADING_SPLASH(state) {
      state.showLoadingSplash = false;
    }
  },
  actions: {
    loadInitialState({ commit }) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      const opportunityId = params.oppid;
      if(!opportunityId) {
        commit("UPDATE_ERROR", { code: 400, message: "No opportunity ID supplied" });
        return;
      }

      if(params.sub)
        commit("UPDATE_STATE", { prop: "sub", value: params.sub });

      var query = isLocal() ? `http://localhost:7071/api/opportunity/${opportunityId}` : `/api/opportunity/${opportunityId}`;

      Axios.get(query)
      .then(response => {
        if(response.data.clientId == "00000000-0000-0000-0000-000000000000") {
          commit("UPDATE_ERROR", { code: 400, message: "No opportunity found for this opportunity ID." })
          return;
        }
        
        commit("INIT_OPPORTUNITY", response.data);
        commit("INIT_PRE_SELECTED_PACKAGES");

        var dynamicStrings = "";
        if(getStandardSourceCampaignName(response.data) == "CONQUEST NET NEW") {
          dynamicStrings = conquest_net_new_strings;
        }
        else if(getStandardSourceCampaignName(response.data) == "CURRENT CLIENT UPGRADE") {
          dynamicStrings = current_client_upgrade_strings;
        }
        else if(getStandardSourceCampaignName(response.data) == "CONQUEST SWITCH") {
          console.log("Switch");
          dynamicStrings = conquest_switch_strings;
        }

        commit("INIT_DYNAMIC_STRINGS", dynamicStrings); 

        commit("UPDATE_LOADING_STATUS", { prop: "opportunity", value: true });

        // Set agreement defaults to true, as requested =\
        commit("UPDATE_OPPORTUNITY", { prop: "tou_Accepted", value: true });
        commit("UPDATE_OPPORTUNITY", { prop: "billImr", value: true });
        commit("UPDATE_OPPORTUNITY", { prop: "authorizedSigner", value: true });
      })
      .catch(error => {
        console.log(error);
        commit("UPDATE_ERROR", { code: error.response?.status ?? "Unknown", message: error?.response ?? "An unknown error has occurred."})
      });
    },
    updateSelectedAddon({ state, commit }, { addon, gifts }) {
      commit("UPDATE_STATE", {prop: "addonOptOut", value: false });

      if(state.selectedAddon === addon) {
        commit("UPDATE_SELECTED_ADDON", "");
      }
      else {
        commit("UPDATE_SELECTED_ADDON", addon);
      }
    },
    updateOpportunity({commit}, payload) {
      commit("UPDATE_OPPORTUNITY", payload);
    },
    showModal({commit}) {
      commit("SHOW_MODAL");
    },
    hideModal({commit}) {
      commit("HIDE_MODAL");
    },
    showSignatureModal({commit}) {
      commit("SHOW_SIGNATURE_MODAL");
    },
    updateSignature({commit}, payload) {
      commit("UPDATE_SIGNATURE", payload);
    },
    sendFullStoryEvent({state}, { FS, eventName, eventPayload }) {
      // try {
      //   if(!eventPayload) {
      //     FS.event(eventName);
      //   }
      //   else {
      //     FS.event(eventName, eventPayload);
      //   }
      // }
      // catch(error) {
      //   console.warn(error);
      // }
    },
    async validateAndSubmitContract({state, getters, commit}) {
      var incompleteItems = [];

      // Validate dealership information in the form
      !getters.dealerInformation.dealerName?.trim() ? incompleteItems.push("Dealership Name field is empty") : null;
      !getters.dealerInformation.bacCode?.trim() ? incompleteItems.push("BAC field is empty") : null;
      !getters.opportunity.digitalAirstrikeRepresentative?.trim() ? incompleteItems.push("Digital Air Strike Representative field is empty") : null;

      // Validate dealer address info in the form
      !getters.address.street?.trim() ? incompleteItems.push("Dealer street address field is empty") : null;
      !getters.address.city?.trim() ? incompleteItems.push("Dealer city field is empty") : null;
      !getters.address.state?.trim() ? incompleteItems.push("Dealer state field is empty") : null;
      !getters.address.zip?.trim() ? incompleteItems.push("Dealer ZIP code field is empty") : null;

      // Package and addon checks
      //!state.selectedPackage ? incompleteItems.push("No package selected") : null;
      if(state.sourceCampaign == "CURRENT CLIENT UPGRADE") {
        state.selectedPackages.length < 1 ? incompleteItems.push("Please select at least one upgrade to proceed.") : null;
      }
      else {
        !state.selectedPackages.some(pkg => pkg.type.toUpperCase() === "GM") ? incompleteItems.push("No GM package(s) selected") : null;
      }

      (state.selectedPackages.some(pkg => pkg.name.toUpperCase().includes("REPUTATION DEFEND")) && state.selectedPackages.some(pkg => pkg.name.toUpperCase().includes("REPUTATION ENGAGE"))) ? incompleteItems.push("Please select only one reputation package") : null;
      
      // Make sure Defend is included if that's what the dealer was already on and they haven't upgraded or opted out
      // if (state.originalPackages.includes("GOOGLE BUSINESS PROFILE DEFEND") && !state.selectedPackages.some(pkg => pkg.type.toUpperCase() === "GOOGLE") && !state.googleOptOut) {
      //   state.selectedPackages.push(packages.googleBusinessProfileDefend);
      // }

      if (!state.isOnHighestGooglePackage) {
        if (!state.selectedPackages.some(pkg => pkg.type.toUpperCase() === "GOOGLE") && !state.googleOptOut) {
          incompleteItems.push("Please select a Google Business Profile package, or decline them");
        }
      }
      
      // Disabled as step 3 addons have been disabled in 2025
      //(!state.selectedAddon && !state.addonOptOut) ? incompleteItems.push("Please select a Power Pack, or decline them") : null;
      state.selectedAddon = ""; // Make sure this is set to nothing

      // Required checkboxes
      !state.opportunity.tou_Accepted ? incompleteItems.push("Terms and Conditions not accepted") : null;
      !state.opportunity.authorizedSigner ? incompleteItems.push("Authorized signer has not been confirmed") : null;

      // User information validation
      !state.signature.completed ? incompleteItems.push("Please sign the form") : null;
      !state.opportunity.signaturePrintedName?.trim() ? incompleteItems.push("Please type your full name into the 'Printed Name' field") : null;
      !state.opportunity.signatoryTitle?.trim() ? incompleteItems.push("Please add your job title") : null;

      if(incompleteItems.length > 0) {
        commit("SHOW_INCOMPLETE_ITEMS_MODAL", { title: "Whoops!", messages: incompleteItems });
        return;
      }

      commit("SHOW_LOADING_SPLASH");

      const finalizedOpportunity = JSON.parse(JSON.stringify(state.opportunity));

      let ipResponse = null;
      try {
        ipResponse = await (await Axios.get(`https://api.ipify.org?format=json`)).data;
        finalizedOpportunity.ipAddress = ipResponse.ip;
      }
      catch(error) {
        console.warn(error);
        commit("SHOW_ERROR_MODAL", { title: "Error", message: error });
        commit("HIDE_LOADING_SPLASH");
        return;
      }

      finalizedOpportunity.packages = [];
      finalizedOpportunity.packages = state.selectedPackages.map(pkg => ({
        id: pkg.id,
        name: pkg.fullName,
        type: pkg.type
      }));

      var addon = state.selectedAddon === "Best" ? { id: addons.best.id, name: addons.best.fullName, type: "Addon"  }
        : state.selectedAddon === "Better" ? { id: addons.better.id, name: addons.better.fullName, type: "Addon"  }
        : state.selectedAddon === "Good" ? { id: addons.good.id, name: addons.good.fullName, type: "Addon"  }
        : null;

      if(addon) {
        finalizedOpportunity.packages.push(addon);
      }

      finalizedOpportunity.sub = state.sub;

      finalizedOpportunity.signature = state.signature;

      finalizedOpportunity.googleOptOut = state.googleOptOut;
      finalizedOpportunity.addonOptOut = state.addonOptOut;

      if(isLocalOrDev()) {
        finalizedOpportunity.dealerInformation.contactEmail = "12d8533f-e538-4a7c-9d15-4db2c541a967@a1c1c603-86dc-4e72-8951-79a92b1db638.com";
      }

      var apiEndpoint = isLocal()
      ? "http://localhost:7071/api/opportunity"
      : "/api/opportunity";

      // commit("SHOW_ERROR_MODAL", { title: "Error", message: "Form submission is currently disabled. Please check the console logs for the opportunity POST payload." });
      // commit("HIDE_LOADING_SPLASH");

      // console.log("Opportunity POST Payload:", finalizedOpportunity);
      // return;

      Axios.post(apiEndpoint, finalizedOpportunity, { timeout: 90000 })
        .then(response => {
          commit("UPDATE_OPPORTUNITY", { prop: "contractSigned", value: true });
          commit("UPDATE_OPPORTUNITY", { prop: "pdfUrl", value: response.data.contractUrl });
        })
        .catch(error => {
          console.warn(error);
          commit("SHOW_ERROR_MODAL", { title: "Error", message: error });
        })
        .then(() => {
          commit("HIDE_LOADING_SPLASH");
        });
    },
  },
  getters: {
    appLoading: state => { return (!state.loaded.opportunity) },
    opportunity: state => state.opportunity,
    dealerInformation: state => state.opportunity.dealerInformation,
    address: state => state.opportunity.address,
    strings: state => name => state.strings.filter(string => string.name === name)[0]?.value ?? "",
    stringObject: state => name => state.strings.filter(string => string.name === name)[0],
    dynamicStrings: state => (category, key) => state.dynamicStrings[category]?.[key] ?? "",
    dynamicStringCategory: state => (category) => state.dynamicStrings[category],
    link: state => linkName => state.links[linkName],
    signatureModalVisible: state => (state.modal.subview == "signature" && state.modal.show == true),
    sourceCampaign: state => getStandardSourceCampaignName(state.opportunityOriginal),
    isOnHighestRMPackage: state => state.isOnHighestRMPackage,
    isOnHighestSMRMPackage: state => state.isOnHighestSMRMPackage,
    isOnHighestGooglePackage: state => state.isOnHighestGooglePackage
  },
  modules: {

  }
})

const getStandardPackageNameArray = (packageArray) => {
  var packageNames = packageArray.map(pkg => pkg.name.toUpperCase());
  var standardPackageNames = [];

  if(packageNames.some(pkg => pkg.includes("REPUTATION DEFEND"))) {
    standardPackageNames.push("REPUTATION DEFEND");
  }

  if(packageNames.some(pkg => pkg.includes("REPUTATION ENGAGE"))) {
    standardPackageNames.push("REPUTATION ENGAGE");
  }

  if(packageNames.some(pkg => pkg.includes("SOCIAL DOMINATE"))) {
    standardPackageNames.push("SOCIAL DOMINATE");
  }

  if(packageNames.some(pkg => pkg.includes("GOOGLE BUSINESS PROFILE DOMINATE"))) {
    standardPackageNames.push("GOOGLE BUSINESS PROFILE DOMINATE");
  }

  if(packageNames.some(pkg => pkg.includes("GOOGLE BUSINESS PROFILE DEFEND"))) {
    standardPackageNames.push("GOOGLE BUSINESS PROFILE DEFEND");
  }

  return standardPackageNames;
}

const getStandardSourceCampaignName = (opp) => {
  if(!opp.sourceCampaign)
    return "CONQUEST NET NEW";

  if (opp.sourceCampaign.toUpperCase().includes("NET NEW")) {
    return "CONQUEST NET NEW";
  }
  else if (opp.sourceCampaign.toUpperCase().includes("SWITCH")) {
    return "CONQUEST SWITCH";
  }
  else if (opp.sourceCampaign.toUpperCase().includes("UPGRADE")) {
    return "CURRENT CLIENT UPGRADE";
  }
  else {
    return "CONQUEST NET NEW";
  }
}

const getPackagesForPOST = (packageArray) => {
  return packageArray.map(pkg => ({
    id: pkg.id,
    name: pkg.name,
    type: pkg.type,
    year: "2024"
  }));
}